<template>
  <!-- Page Content -->
  <div class="my-2">
    <h2 class="my-4 text-4xl font-semibold">Unit Images</h2>
    <div class="pb-2 flex items-center justify-between text-gray-600">
      <div>
        <table class="min-w-max w-full mt-5 table-auto">
          <thead>
            <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th class="py-3 px-6 text-center">Name</th>
              <th class="py-3 px-6 text-center">Path</th>
              <th class="py-3 px-6 text-center">Token</th>
            </tr>
          </thead>
          <tbody class="text-gray-600 text-sm font-light">
            <tr
              v-for="(object, i) in objects"
              :key="i"
              class="border-b border-gray-200 hover:bg-gray-100"
            >
              <td class="py-3 px-6 text-left whitespace-nowrap">
                {{ object.name }}
              </td>
              <td class="py-3 px-6 text-left whitespace-nowrap">
                {{ object.fullPath }}
              </td>
              <td class="py-3 px-6 text-left whitespace-nowrap">
                {{ object.token }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <input
      type="search"
      v-model="query"
      placeholder="Search..."
      class="w-full p-2 mb-2"
    />
  </div>
</template>

<script>
 import firebase from "firebase";

export default {
  name: "AdminUsersList",
  components: {

  },
  data: () => ({
    query: "",
    objects: [],
  }),
  methods: {

 
    getImages() {
      // Set the bucket name.
      var bucket = firebase.storage().ref("unit-images")
      // Get all objects in the bucket.
      bucket.listAll().then((result) => {
        // Create an empty array to store the results in.
        var objects = []
        // Loop through each item in the bucket.
        result.items.forEach(async function(object) {
          // We need to do an await to go back to firebase
          // and get the token (additional information) for each
          // file or object.
          await object.getDownloadURL().then(function(url) {
              // Add a new property to the
              // existing object.
              object.token = url
            }).catch(function(error) {
              console.log(error)
            });
          objects.push(object)
        });
        // Throw everything back into this.
        this.objects = objects
      }).catch(function(error) {
        console.log(error)
      });
    },
  },
  created() {
    this.getImages()
  },
};
</script>

<style>

</style>
