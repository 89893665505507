<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div>
      <h2 class="my-4 text-4xl font-semibold">Extra's</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit the global interactive extra's settings</p>
      </div>
    </div>

    <!-- Settings Content -->

    <!-- Parking Settings Content -->
    <div class="flex flex-col">
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5 font-bold text-lg">Parking Settings</h4>
      </div>

      <div class="w-full mt-5">
        <label class="w-full">Total Number of Parking Bays:</label>
        <input
            v-model="extraSettings.parkingTotalBays"
            class="lb-admin-form-input rounded"
            placeholder="If none then insert 0"
            type="Number"
        />
      </div>

      <div class="w-full mt-5">
        <div v-if="extraSettings.isParkingOne ">
          <div class="flex align-center">
            <label class="flex align-center mr-2">One Parking bay:</label>
            <o-switch v-model="extraSettings.isParkingOne" :required="false"></o-switch>
          </div>
          <div class="parkingBayContainer">
            <div class="w-full">
              <label class="w-full">Name:</label>
              <input
                  v-model="extraSettings.parkingOneName"
                  class="lb-admin-form-input rounded"
                  placeholder="Single"
                  type="text"
              />
            </div>

            <div class="w-full">
              <label class="w-full">Price:</label>
              <input
                v-model="extraSettings.parkingOnePrice"
                class="lb-admin-form-input rounded"
                placeholder="If none then insert 0"
                type="number"
              />
            </div>

            <div class="w-full">
              <label class="w-full">Quantity:</label>
              <input
                v-model="extraSettings.parkingOneAmounts"
                class="lb-admin-form-input rounded"
                type="number"
              />
            </div>
          </div>
        </div>
        <div v-else class="flex align-center mt-5">
          <label class="flex align-center mr-2">One Parking bay:</label>
          <o-switch v-model="extraSettings.isParkingOne" :required="false"></o-switch>
        </div>
      </div>

      <div class="mt-5" v-if="extraSettings.isParkingTandem ">
        <div class="flex align-center">
          <label class="flex align-center mr-2">Tandem Parking bay:</label>
          <o-switch v-model="extraSettings.isParkingTandem" :required="false"></o-switch>
        </div>
        <div class="parkingBayContainer">
          <div class="w-full">
            <label class="w-full">Name:</label>
            <input
                v-model="extraSettings.parkingTandemName"
                class="lb-admin-form-input rounded"
                placeholder="Tandem"
                type="text"
            />
          </div>

          <div class="w-full">
            <label class="w-full">Price:</label>
            <input
              v-model="extraSettings.parkingTandemPrice"
              class="lb-admin-form-input rounded"
              placeholder="If none then insert 0"
              type="number"
            />
          </div>

          <div class="w-full">
            <label class="w-full">Quantity:</label>
            <input
              v-model="extraSettings.parkingTandemAmounts"
              class="lb-admin-form-input rounded"
              type="number"
            />
          </div>
        </div>
      </div>
      <div v-else class="flex align-center mt-5">
        <label class="flex align-center mr-2">Tandem Parking bay:</label>
        <o-switch v-model="extraSettings.isParkingTandem" :required="false"></o-switch>
      </div>

<!--      <div class="mt-5" v-if="extraSettings.isParkingTwo ">-->
<!--        <div class="flex align-center">-->
<!--          <label class="flex align-center mr-2">Two Parking bays:</label>-->
<!--          <o-switch v-model="extraSettings.isParkingTwo" :required="false"></o-switch>-->
<!--        </div>-->
<!--        <div class="grid grid-cols-3 gap-2 mb-2">-->
<!--          <div class="w-full">-->
<!--            <label class="w-full">Name:</label>-->
<!--            <input-->
<!--                v-model="extraSettings.parkingTwoName"-->
<!--                class="lb-admin-form-input rounded"-->
<!--                placeholder="Double"-->
<!--                type="text"-->
<!--            />-->
<!--          </div>-->

<!--          <div class="w-full">-->
<!--            <label class="w-full">Price:</label>-->
<!--            <input-->
<!--              v-model="extraSettings.parkingTwoPrice"-->
<!--              class="lb-admin-form-input rounded"-->
<!--              placeholder="If none then insert 0"-->
<!--              type="number"-->
<!--            />-->
<!--          </div>-->

<!--          <div class="w-full">-->
<!--            <label class="w-full">Quantity:</label>-->
<!--            <input-->
<!--              v-model="extraSettings.parkingTwoAmounts"-->
<!--              class="lb-admin-form-input rounded"-->
<!--              type="number"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-else class="flex align-center mt-5">-->
<!--        <label class="flex align-center mr-2">Two Parking bay:</label>-->
<!--        <o-switch v-model="extraSettings.isParkingTwo" :required="false"></o-switch>-->
<!--      </div>-->
    </div>

    <!-- Guaranteed Rental Settings Content -->
    <div class="flex flex-col">
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5 font-bold text-lg">Guaranteed Rental Settings</h4>
      </div>
      <div class="w-full mt-5">
        <div v-if="extraSettings.isGuaranteedRental ">
           <div class="flex align-center">
              <label class="flex align-center mr-2">Guaranteed Rental:</label>
              <o-switch v-model="extraSettings.isGuaranteedRental" :required="false"></o-switch>
            </div>
            <div style="padding-bottom:5px;">
              <p>Enable this to allow users to select Guaranteed Rental as an optional extra in the checkout process.
              <br>This will add the Guaranteed Rental Price to their total Purchase Price.
              </p>
            </div>
          <div class="guaranteedRentalContainer">
            <div class="w-full">
              <label class="w-full">Name:</label>
              <input
                  v-model="extraSettings.guaranteedRentalName"
                  class="lb-admin-form-input rounded"
                  placeholder="1 Year"
                  type="text"
              />
            </div>

            <div class="w-full">
              <label class="w-full">Price:</label>
              <input
                  v-model="extraSettings.guaranteedRentalPrice"
                  class="lb-admin-form-input rounded"
                  placeholder="If none then insert 0"
                  type="number"
              />
            </div>
          </div>
        </div>
        <div v-else class=" mt-5">
            <div class="flex align-center">
              <label class="flex align-center mr-2">Guaranteed Rental:</label>
              <o-switch v-model="extraSettings.isGuaranteedRental" :required="false"></o-switch>
            </div>
            <div style="padding-bottom:5px;">
              <p>Enable this to allow users to select Guaranteed Rental as an optional extra in the checkout process.
              <br>This will add the Guaranteed Rental Price to their total Purchase Price.
              </p>
            </div>
        </div>
      </div>
    </div>

    <!-- Page Content -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        @click.prevent="updateSettings"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Save
      </button>
    </div>

  </div>
</template>

<script>
import { settingsCollection } from "../../firebase.js";

export default {
  name: "AdminExtraSettings",
  components: {
  },
  data: () => ({
    extraSettings: {
      parkingTotalBays: null,

      isParkingOne: false,
      parkingOnePrice: null,
      parkingOneAmounts: null,
      parkingOneName: null,

      isParkingTandem: false,
      parkingTandemPrice: null,
      parkingTandemAmounts: null,
      parkingTandemName: null,

      isParkingTwo: false,
      parkingTwoPrice: null,
      parkingTwoAmounts: null,
      parkingTwoName: null,

      isGuaranteedRental: false,
      guaranteedRentalName: null,
      guaranteedRentalPrice: null,
    },
  }),
  methods: {
    updateSettings() {
      if (confirm("Are you sure you want to proceed?")) {
        settingsCollection
          .doc("extraSettings")
          .update({
            parkingTotalBays: this.extraSettings.parkingTotalBays || null,

            isParkingOne: this.extraSettings.isParkingOne || false,
            parkingOnePrice: this.extraSettings.parkingOnePrice || null,
            parkingOneAmounts: this.extraSettings.parkingOneAmounts || null,
            parkingOneName: this.extraSettings.parkingOneName || null,

            isParkingTandem: this.extraSettings.isParkingTandem || false,
            parkingTandemPrice: this.extraSettings.parkingTandemPrice || null,
            parkingTandemAmounts: this.extraSettings.parkingTandemAmounts || null,
            parkingTandemName: this.extraSettings.parkingTandemName || null,

            isParkingTwo: this.extraSettings.isParkingTwo || false,
            parkingTwoPrice: this.extraSettings.parkingTwoPrice || null,
            parkingTwoAmounts: this.extraSettings.parkingTwoAmounts || null,
            parkingTwoName: this.extraSettings.parkingTwoName || null,

            isGuaranteedRental: this.extraSettings.isGuaranteedRental || false,
            guaranteedRentalName: this.extraSettings.guaranteedRentalName || null,
            guaranteedRentalPrice: this.extraSettings.guaranteedRentalPrice || null,
          })
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Extra Settings Updated",
            });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Extra Settings Update Error",
              text: error.message,
            });
          });
      }
    },
  },
  created() {
    settingsCollection
      .doc("extraSettings")
      .get()
      .then((doc) => {
        (this.extraSettings.parkingTotalBays = doc.data().parkingTotalBays || null);

        (this.extraSettings.isParkingOne = doc.data().isParkingOne || false);
        (this.extraSettings.parkingOnePrice = doc.data().parkingOnePrice || null);
        (this.extraSettings.parkingOneAmounts = doc.data().parkingOneAmounts || null);
        (this.extraSettings.parkingOneName = doc.data().parkingOneName || null);

        (this.extraSettings.isParkingTandem = doc.data().isParkingTandem || false);
        (this.extraSettings.parkingTandemPrice = doc.data().parkingTandemPrice || null);
        (this.extraSettings.parkingTandemAmounts = doc.data().parkingTandemAmounts || null);
        (this.extraSettings.parkingTandemName = doc.data().parkingTandemName || null);

        (this.extraSettings.isParkingTwo = doc.data().isParkingTwo || false);
        (this.extraSettings.parkingTwoPrice = doc.data().parkingTwoPrice || null);
        (this.extraSettings.parkingTwoAmounts = doc.data().parkingTwoAmounts || null);
        (this.extraSettings.parkingTwoName = doc.data().parkingTwoName || null);

        (this.extraSettings.isGuaranteedRental = doc.data().isGuaranteedRental || false);
        (this.extraSettings.guaranteedRentalName = doc.data().guaranteedRentalName || null);
        (this.extraSettings.guaranteedRentalPrice = doc.data().guaranteedRentalPrice || null);
      });
  },
};
</script>

<style scoped>
.o-btn {
  @apply bg-primary;
}
.parkingBayContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.guaranteedRentalContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .parkingBayContainer {
    display: block;
  }

  .guaranteedRentalContainer {
    display: block;
  }
}
</style>
