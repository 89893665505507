<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div>
      <h2 class="my-4 text-4xl font-semibold">Settings</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit the global interactive price list settings</p>
      </div>
    </div>

    <!-- Settings Content -->

    <!-- Launch Data Collection -->
    <div class="w-full ">
      <h4 class="block w-full mt-5 font-bold text-lg">Launch Settings</h4>

    <!-- Launch Details Content -->
    <div class="w-full">
      <div class="w-full mt-5">
        <o-switch v-model="settings.isLaunchDate">
          Display Launch Date Countdown Timer / Unit Sold Count
        </o-switch>
        <p class="mt-1 text-xs lg:w-3/5">
          Enable this to display countdown timer in navigation bar.<br>
          When the countdown reaches zero, this will automatically display the number of sold units.
        </p>
      </div>

      <div class="w-full lg:w-1/3 mt-5 pr-6 mb-2">
        <label class="w-full">Set Sales Launch Date</label>
        <br/>
        <o-datetimepicker
          v-model="settings.launchDate"
          placeholder="Click to select..."
          class="w-full rounded"
          override
        >
          <div class="buttons-footer w-full flex justify-between mb-2 px-2">
            <o-button
              class="bg-primary px-4 py-2 text-white font-semibold rounded"
              @click="settings.launchDate = new Date()"
            >
              <o-icon icon="calendar"></o-icon>
              <span>Today</span>
            </o-button>
            <o-button
              class="bg-primary px-4 py-2 text-white font-semibold rounded"
              @click="settings.launchDate = null"
            >
              <o-icon icon="times"></o-icon>
              <span>Clear</span>
            </o-button>
          </div>
        </o-datetimepicker>
      </div>
    </div>


      <div class="mt-5 mb-2 ">
        <o-switch v-model="settings.dataCollection">
        Enable this to make Login/Registration required
        </o-switch>
        <p class="mt-1 text-xs">
          Enable this to only allow users with accounts to access the site via logging in or registering.<br>
          If disabled, anyone can view the Price List without having to login.
        </p>
      </div>

      <div class="mt-5">
        <o-switch  v-model="settings.allowDeposit">
          Enable Reservation Deposit (Paystack)
        </o-switch>
        <p class="mt-1 text-xs">
          When disabled, buyers will be able to reserve a unit without paying a reservation deposit.
        </p>
      </div>

      <div class="w-full mt-5" v-if="settings.allowDeposit">
        <label class="w-full">Deposit Amount</label>
        <input
          v-model="settings.depositAmount"
          class="lb-admin-form-input rounded"
          type="number"
        />
      </div>

        <div class="mt-5">
        <o-switch v-model="settings.allowReserve">
          Display Reservation Button
        </o-switch>
        <p class="mt-1 text-xs">
          When disabled, a CONTACT button will display in place of the RESERVE button.
        </p>
      </div>

      <div class="mt-5">
        <o-switch v-model="settings.hidePriceOnSold">
          Hide Unit Price When Sold/Reserved/Pending
        </o-switch>
      </div>
    </div>
  <div class="border-b mt-4"></div>
  
    <!-- General Global Settings Content -->
    <div class="w-full">
      <h4 class="block w-full mt-5 font-bold text-lg">Discount Settings</h4>

   <div class="mt-5 hidden">
        <o-switch v-model="settings.allowDiscount">
          Enable Unit Specific Discount
        </o-switch>
        <p  class="mt-1 text-xs">Enable this to allow individual units to be discounted</p>
      </div>

      <div class="mt-5">
        <o-switch
          v-model="settings.allowGlobalDiscount">
          Enable Global Discount
        </o-switch>
        <p  class="mt-1 text-xs">Enable this to allow discounts to be applied to all units on price list.</p>
      </div>


      
      <div class="w-full mt-5" v-if="settings.allowGlobalDiscount">
        <label class="w-full">Global Discount Type</label>
        <select
          class="lb-admin-form-input rounded"
          v-model="settings.globalDiscountType"
        >
          <option value="percentage">Percentage</option>
          <option value="amount">Fixed Amount</option>
        </select>
      </div>

          <div class="w-full mt-5" v-if="settings.allowGlobalDiscount">
        <label class="w-full">Global Discount Amount</label>
        <input
          v-model="settings.globalDiscountAmount"
          class="lb-admin-form-input rounded"
          type="number"
        />
      </div>  

            <div class="mt-5"
       v-if="settings.allowGlobalDiscount === true">
        <o-switch
          v-model="settings.allowDiscountNoSurvey">
          Enable Global Discount without Survey
        </o-switch>
        <p  class="mt-1 text-xs">Enable this to allow discounts to be applied to 
          all units on the Price List without the user being required to fill in a survey to access it.<br>
          Disable this if a user is required to fill in a survey in order to access their discount.</p>
      </div>

      <div class="w-full lg:w-1/3 mt-5 hidden">
        <o-switch v-model="settings.setOneDayOnlyDiscount">
          Enable Unique Unit individual Promotions
        </o-switch>
      </div>
      
      <div class="border-b mt-4"></div>
      <h4 class="block w-full mt-5 font-bold text-lg">General Settings</h4>

        <div class="mt-5 mb-2 ">
        <o-switch v-model="settings.showAvailableUnits">
          Display Available Units Only as Default
        </o-switch>
        <p class="mt-1 text-xs">
          When disabled, all units will display on price list.
        </p>
      </div>
    </div>

    
      <div class="w-full mt-5">
        <label class="w-full">Interest Rate %</label>
        <input
          v-model="settings.interestRate"
          class="lb-admin-form-input rounded"
          type="number"
        />
      </div>

    <div class="w-full mt-5">
      <o-switch class="mb-2" v-model="settings.displaySplashScreen">
        Activate pop-up message
      </o-switch>

      <div class="mb-2" v-if="settings.displaySplashScreen">
        <label class="block w-full mt-5">Enter pop-up message</label>
        <BaseEditor v-model="settings.splashScreenMessage" />
      </div>
    </div>

    <!-- Shortlist unit Settings Content -->
    <div class="flex flex-col">
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5  text-lg">Shortlist Counter</h4>
        <p class="mt-1 text-xs">
          Add the minimum number of times a unit must be shortlisted to display the 
          shortlist counter on the price list. I.e. Shortlisted by 2 users.
        </p>
      </div>

      <div class="w-full">
        <input
            v-model="settings.shortlistUnitShow"
            class="lb-admin-form-input rounded"
            placeholder="If none then insert 0"
            type="number"
        />
      </div>
    </div>

      <!-- URL Link Content -->
    <div class="flex flex-col">
      <div class="w-full mt-5">
         <h4 class="block w-full mt-5 text-lg">
          <o-switch v-model="settings.isUrlLink" :required="false"></o-switch>
          Add Custom Button to Congratulations Page  
        </h4>
        <!-- 
          <p>This url link is displayed on the thank you screen after Unit has been SUCCESS payment.</p>
         -->
      </div>

      <div class="flex">
        <div class="fields-flex w-full lg:w-1/3 mt-5">
          <div v-if="settings.isUrlLink ">
            <div class="flex align-center">
              <label class="flex align-center mr-2">URL Link:</label>
            </div>
            <input
                v-model="settings.urlLink"
                class="lb-admin-form-input-select rounded w-full"
                type="text"
                placeholder="URL: https://example.co.za/"
            />
          </div>
          <div v-else class="flex hidden align-center mt-5">
            <label class="flex align-center mr-2">URL Link:</label>
            <o-switch v-model="settings.isUrlLink" :required="false"></o-switch>
          </div>
        </div>

        <div
            v-if="settings.isUrlLink"
            class="fields-flex w-full lg:w-1/3 mt-5"
        >
          <label class="flex align-center mr-2">URL Link Button:</label>
          <input
              v-model="settings.urlLinkButton"
              class="lb-admin-form-input-select rounded w-full"
              type="text"
          />
        </div>
      </div>
    </div>

  <!-- Users Online Settings Content -->
    <div class="flex flex-col">
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5  text-lg">
          <o-switch v-model="settings.isUsersOnline" :required="false"></o-switch>
          Show Realtime Online User Count
        </h4>
        <p class="mt-1 text-xs">
          This shows a green bar at the top of the screen which displays the amount of users currently viewing the price list.
        </p>
      </div>
    </div>


    <!-- Override Action Display Settings Content -->
    <div class="flex flex-col w-full">
        <div class="fields-flex w-full mt-5 ">
          <!--Start of IF statement-->
          <div v-if="settings.isGlobalOverrideAction ">
            <div>
            <div class="flex align-center">
              <h4 class="block w-full mt-5  text-lg">
                <o-switch v-model="settings.isGlobalOverrideAction" :required="false"></o-switch>
                Global Reserve Button Override
              </h4>
            </div>
            <div class="flex align-center lg:w-2/4">
               <p class="mt-1 text-xs">
                Enable this to replace the RESERVE button with a custom button.<br>
                <span style="color:red">NB: Disable this 1 day before sales go live.</span>
              </p>
            </div>
              <div class="mt-5"></div>
            </div>
            <!--buttons start-->
            <div class="flex">
                        <!--Link Button-->
            <div class="lg:w-2/4">
                <label class="flex align-center mr-2">URL Link:</label>
            <input
              v-model="settings.globalOverrideAction"
              class="lb-admin-form-input-select rounded w-full"
              type="text"
              placeholder="URL: https://example.co.za/"
            />
            </div>
                      <!--URL Link Button-->     
          <div
          class="lg:w-2/4"
          v-if="settings.isGlobalOverrideAction"
        >
          <label class="flex align-center mr-2">URL Link Button:</label>
          <input
            v-model="settings.globalOverrideActionButton"
            class="lb-admin-form-input-select rounded w-full "
            type="text"
          />
        </div>
          </div>
          <!--end of buttons div-->
          </div>
          <!--Start of ELSE statement-->
          <div v-else class="flex align-center mt-5 lg:w-2/4">
            <div>
            <div class="flex align-center mt-5">
              <h4 class="block w-full mt-5  text-lg">
                <o-switch v-model="settings.isGlobalOverrideAction" :required="false"></o-switch>
                Global Reserve Button Override
              </h4>
            </div>
            <div class="flex align-center mt-5">
              <p class="mt-1 text-xs">
                Enable this to replace the RESERVE button with a custom button.<br>
                <span style="color:red">NB: Disable this 1 day before sales go live.</span>
              </p>
            </div>
            </div>
          </div>
      </div>
    </div>

    <div class="mt-5 mb-2 ">
      <o-switch v-model="settings.bypassAdmin">
        Launch Counter bypass
      </o-switch>
      <p class="mt-1 text-xs">
        When enabled, Allows admin, admin-viewonly and superadmin to bypass the counter.
      </p>
    </div>
      <div class="border-b mt-4"></div>

     <!-- Artwork Uploads Content -->
    <div class="flex flex-col borderArtwork">
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5 font-bold text-lg">Artwork Uploads</h4>
      </div>
      <!-- Header and Banner Images Uploads Content -->
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5 font-bold text-lg">Header and Banner Images</h4>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <div class="mt-5">
          <label class="w-full">Developer/development logo<br>(Max 150 x 50px PNG)</label>
          <UploadImage class="lg:w-1/3" v-model="settings.logo" />
        </div>

        <div class="mt-5">
          <label class="w-full">Header featured image<br>(1450 x 350px Jpg)</label>
          <UploadImage v-model="settings.headerImage" />
        </div>
      </div>

      <!-- Header and Banner Images Uploads Content -->
      <div class="w-full mt-5">
        <h4 class="block w-full mt-5 font-bold text-lg">Sign In Images</h4>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <div class="mt-5">
          <label class="w-full"></label>
          <label class="w-full">
            Logo
            <br>
            (Max 150 x 50px SVG)
            </label>
          <UploadImage class="lg:w-1/3" v-model="settings.logoSignIn" />
        </div>

        <div class="mt-5">
          <label class="w-full"></label>
          <label class="w-full">
            Background featured image on Login
            <br>
            (500 x 607px Jpg)
            </label>
          <UploadImage v-model="settings.backgroundSignIn" />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-3">
        <div class="mt-5">
          <label class="w-full"></label>
          <label class="w-full">
          Background featured image on Register
          <br>
          (500 x 777px Jpg)
          </label>
          <UploadImage v-model="settings.backgroundRegister" />
        </div>
      </div>
    </div>

    <!-- Page Content -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        @click.prevent="updateSettings"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { settingsCollection } from "../../firebase.js";
import UploadImage from "../../components/admin/UploadImage";
import BaseEditor from "../../components/admin/BaseEditor";
import {GLOBAL_OVERRIDE_ACTION_BUTTON_DEFAULT} from "@/Constants";

export default {
  name: "AdminSettings",
  components: {
    UploadImage,
    BaseEditor,
  },
  data: () => ({
    settings: {
      allowReserve: true,
      allowDiscount: true,
      displaySplashScreen: false,
      hidePriceOnSold: false,
      splashScreenMessage: "",
      isLaunchDate: true,
      launchDate: null,
      logo: null,
      logoSignIn: null,
      headerImage: null,
      backgroundSignIn: null,
      backgroundRegister: null,
      globalDiscountType: null,
      globalDiscountAmount: null,
      allowDiscountNoSurvey: false,
      dataCollection: false,
      allowGlobalDiscount: false,
      interestRate: null,
      depositAmount: null,
      allowDeposit: false,
      setOneDayOnlyDiscount: false,

      isUrlLink: false,
      urlLink: null,
      urlLinkButton: null,

      showAvailableUnits: false,
      bypassAdmin: false,

      shortlistUnitShow: 0,
      isUsersOnline: false,

      isGlobalOverrideAction: false,
      globalOverrideAction: null,
      globalOverrideActionButton: GLOBAL_OVERRIDE_ACTION_BUTTON_DEFAULT || null,
    },
  }),
  watch: {
    'settings.allowGlobalDiscount'(newValue) {
      if (newValue === false){
        this.settings.allowDiscountNoSurvey = false
      }
    }
  },
  methods: {
    updateSettings() {
      if (confirm("Are you sure you want to proceed?")) {
        settingsCollection
          .doc("globalSettings")
          .update({
            allowReserve: this.settings.allowReserve || false,
            allowDiscount: this.settings.allowDiscount || false,
            displaySplashScreen: this.settings.displaySplashScreen || false,
            allowDiscountNoSurvey: this.settings.allowDiscountNoSurvey || false,
            dataCollection: this.settings.dataCollection || false,
            allowGlobalDiscount: this.settings.allowGlobalDiscount || false,
            globalDiscountType: this.settings.allowGlobalDiscount
              ? this.settings.globalDiscountType
              : null,
            globalDiscountAmount: this.settings.allowGlobalDiscount
              ? this.settings.globalDiscountAmount
              : null,
            interestRate: this.settings.interestRate || null,
            splashScreenMessage: this.settings.splashScreenMessage || "",
            isLaunchDate: this.settings.isLaunchDate || false,
            launchDate: this.settings.launchDate || null,
            logo: this.settings.logo || null,
            logoSignIn: this.settings.logoSignIn || null,
            headerImage: this.settings.headerImage || null,
            backgroundSignIn: this.settings.backgroundSignIn || null,
            backgroundRegister: this.settings.backgroundRegister || null,
            hidePriceOnSold: this.settings.hidePriceOnSold || false,
            allowDeposit: this.settings.allowDeposit || false,
            depositAmount: this.settings.allowDeposit
              ? this.settings.depositAmount
              : null,
            setOneDayOnlyDiscount: this.settings.setOneDayOnlyDiscount || false,

            isUrlLink: this.settings.isUrlLink || false,
            urlLink: this.settings.urlLink || null,
            urlLinkButton: this.settings.urlLinkButton || null,

            showAvailableUnits: this.settings.showAvailableUnits || false,
            bypassAdmin: this.settings.bypassAdmin || false,

            shortlistUnitShow: this.settings.shortlistUnitShow || null,

            isUsersOnline: this.settings.isUsersOnline || false,

            isGlobalOverrideAction: this.settings.isGlobalOverrideAction || false,
            globalOverrideAction: this.settings.globalOverrideAction || null,
            globalOverrideActionButton: this.settings.globalOverrideActionButton || null,
          })
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Settings Updated",
            });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Settings Update Error",
              text: error.message,
            });
          });
      }
    },
    getSettings() {
      settingsCollection
        .doc("globalSettings")
        .get()
        .then((doc) => {
          this.settings.allowReserve = doc.data().allowReserve;
          this.settings.allowDiscount = doc.data().allowDiscount;
          this.settings.displaySplashScreen = doc.data().displaySplashScreen;
          this.settings.splashScreenMessage = doc.data().splashScreenMessage;
          this.settings.allowDiscountNoSurvey = doc.data().allowDiscountNoSurvey;
          this.settings.dataCollection = doc.data().dataCollection;
          (this.settings.allowGlobalDiscount = doc.data().allowGlobalDiscount || false);
          (this.settings.globalDiscountType = doc.data().globalDiscountType || null);
          (this.settings.globalDiscountAmount = doc.data().globalDiscountAmount || null);
          (this.settings.isLaunchDate = doc.data().isLaunchDate || false);
          (this.settings.launchDate = doc.data().launchDate ? doc.data().launchDate.toDate() : null);
          this.settings.interestRate = doc.data().interestRate;
          this.settings.logo = doc.data().logo;
          this.settings.logoSignIn = doc.data().logoSignIn;
          this.settings.headerImage = doc.data().headerImage;
          this.settings.backgroundSignIn = doc.data().backgroundSignIn;
          this.settings.backgroundRegister = doc.data().backgroundRegister;
          this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
          (this.settings.allowDeposit = doc.data().allowDeposit || false);
          (this.settings.depositAmount = doc.data().depositAmount || null);
          this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount;

          (this.settings.isUrlLink = doc.data().isUrlLink || false);
          (this.settings.urlLink = doc.data().urlLink || null);
          (this.settings.urlLinkButton = doc.data().urlLinkButton || null);

          (this.settings.showAvailableUnits = doc.data().showAvailableUnits || false);
          (this.settings.bypassAdmin = doc.data().bypassAdmin || false);

          (this.settings.shortlistUnitShow = doc.data().shortlistUnitShow || null);

          (this.settings.isUsersOnline = doc.data().isUsersOnline || false);

          (this.settings.isGlobalOverrideAction = doc.data().isGlobalOverrideAction || false);
          (this.settings.globalOverrideAction = doc.data().globalOverrideAction || null);
          (this.settings.globalOverrideActionButton = doc.data().globalOverrideActionButton || null);
        });
    },
  },
  created() {

  },
  mounted() {
    this.getSettings()
  },
};
</script>

<style scoped>
.o-btn {
  @apply bg-primary;
}

</style>
